import { Link } from "gatsby"
import React from "react"
import nameplate from "../images/nameplate.png"

const Header = () => (
  <header>
    <Link to="/" className="header-brand">
      <img src={nameplate} alt="Victory Chapel" width="250" height="100" />
    </Link>
    <nav>
      <ul>
        <li>
          <Link to="Who">Who we are</Link>
        </li>
        <li>
          <Link to="Statement">Mission Statement</Link>
        </li>
        <li>
          <Link to="Location">Location</Link>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
